import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {Container} from "react-bootstrap"

const NotFoundPage = ({location}) => (
  <Layout location={location}>
    <SEO title="404: Страница не найдена" />
    <Container>
      <h1>Страница не найдена</h1>
      <p>Запрашиваемая страница не существоет на сайте.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
